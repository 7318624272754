import React from "react";
import './Hero.css'
import Header from "../Header/Header";
import NumberCounter from 'number-counter'

// hinh anh
import hero_image from "../../assets/hero_image.png"
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"

import {motion} from 'framer-motion' // thư viện có sẵn trong react


const Hero = () =>{

    const transition = {type : 'spring', duration : 3}
    const mobile = window.innerWidth<=768 ? true : false;

    return(
        <div className="hero" id="home">
            <div className="blur hero-blur"></div>
            <div className="left-h">
                <Header />
                {/* the best ad */}
                <div className="the-best-ad">
                    {/* dùng motion tác động đến thẻ muốn dùng */}
                    <motion.div
                    initial={{left:mobile? '178px' : '240px'}} //ban đầu hiện với vị trí cách 240px từ cạnh trái của phần tử cha
                    whileInView={{left:'8px'}} //nó sẽ di chuyển sang vị trí cách cạnh trái 8px, tạo hiệu ứng chuyển động từ vị trí ban đầu 238px sang 8px.
                    transition={transition}
                    ></motion.div>
                    <span>Câu lạc bộ thể hình lớn nhất cần thơ</span>
                </div>


                {/* Hero Heading */}
                <div className="hero-text">
                    <div>
                        <span className="stroke-text">shape </span>
                        <span>your</span>
                    </div>
                    <div>
                        <span>ideal body</span>
                    </div>
                    <div>
                        <span>
                        Tại đây, chúng tôi sẽ giúp bạn định hình và xây dựng cơ thể lý tưởng của mình, để sống cuộc sống trọn vẹn nhất

                        </span>
                    </div>
                </div>

                {/* figrues -so lieu */}
                <div className="figures">
                    <div>
                        <span>
                            <NumberCounter end={140} start={100} delay='4' preFix="+"/>
                        </span>
                        <span>HLV chuyên gia</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter end={1178} start={850} delay='4' preFix="+"/>
                        </span>
                        <span>Thành viên tham gia</span>
                    </div>
                    <div>
                        <span>
                        <NumberCounter end={50} start={0} delay='4' preFix="+"/>
                        </span>
                        <span>Chương trình tập luyện</span>
                    </div>
                </div>

                {/* hero button */}
                <div className="hero-buttons">
                    <button className="btn">Bắt Đầu</button>
                    <button className="btn">Tìm hiểu thêm</button>
                </div>


            </div>

            <div className="right-h">
                <button className="btn">Tham gia ngay</button>


                <motion.div 
                initial={{right:'-1rem'}}
                whileInView={{right:'4rem'}}
                transition={transition}
                className="heart-rate"
                >
                    <img src={Heart} alt="" />
                    <span>Nhịp tim</span>
                    <span>116bpm</span>
                </motion.div>


                {/* hero image */}
                <img src={hero_image} alt="" className="hero-image" />
                <motion.img 
                initial={{right:'11rem'}}
                whileInView={{right:"20rem"}}
                transition={{...transition}}
                src={hero_image_back} alt="" className="hero-image-back"/>

                {/* calories */}
                <motion.div 
                initial={{right:"37rem"}}
                whileInView={{right:'25rem'}}
                transition={transition}
                className="calories">
                    <img src={Calories} alt="" />
                    <div>
                     <span>Calo tiêu hao</span>
                    <span>220 calo</span>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}
export default Hero