import React, { useRef } from "react";
import './Join.css'
import emailjs from '@emailjs/browser'
const Join = () =>{
    //gửi email về tài khoản
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault(); //ngăn tải trang
    
        emailjs
          .sendForm('service_w5vn7nb','template_27n08cc', form.current, 
             'VkunMm1IRTFofluXO')
          .then((result) => {
              console.log(result.text);
            },(error) => {
              console.log(error.text);
            },
          );
      };
  return(
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr />
            <div>
                <span className="stroke-text">SẴN SÀNG</span>
                <span>THĂNG CẤP</span>
            </div>
            <div>
                <span>CƠ THỂ CỦA BẠN</span>
                <span className="stroke-text">CÙNG CHÚNG TÔI</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder="Nhập địa chỉ email của bạn"/>
                <button className="btn btn-j">Tham gia ngay</button>
            </form>
        </div>
    </div>
  )
}
export default Join