import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Tôi đã đưa ra lựa chọn đúng đắn khi chọn Fitclub và chọn đúng gói cũng như chương trình. Tôi đã đạt được cơ thể lý tưởng của mình!",
    name: 'MATHEW HENDRICKSON',
    status : 'DOANH NHÂN'
  },
  {
    image: image2,
    review: 'Tôi rất hài lòng với dịch vụ của FitClub , họ đã mang đến cho tôi một trải nghiệm tuyệt vời, tạo ra nguồn cảm hứng cho cuộc sống ',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:'Đây là một dịch vụ tốt nhất mà tôi từng biết, họ có đội ngũ chuyên nghiệp, nhiệt tình và chu đáo, xứng đáng được đánh giá 5 sao',
    name: 'FRANKLIN',
    status: "khách hàng"
  }
];
