import React, { useState } from "react"
import './Testimonials.css'
import { testimonialsData } from "../../data/testimonialsData"
import LeftArrows from '../../assets/leftArrow.png'
import RightArrows from '../../assets/rightArrow.png'
import { motion } from "framer-motion"

const Testimonials = () =>{
 
     const transition ={type:'spring', duration:3};
     const [selected,setSelected] = useState(0);
     const tLength = testimonialsData.length;


     return(
        <div className="Testimonials">
            <div className="left-t">
                <span>Cảm nhận của khách hàng</span>
                <span className="stroke-text">Họ nói gì</span>
                <span>về chúng tôi</span>
                <motion.span
                key={selected}
                initial={{opacity:0, x: -100}}
                animate={{opacity:1, x:0}}// khong giong whileinview, click item moi
                exit={{opacity:0, x:100}}
                transition={transition}
                >
                    {testimonialsData[selected].review}
                </motion.span>
                <span>
                    <span style={{color:"var(--orange)"}}>
                        {testimonialsData[selected].name} 
                    </span>{" "}
                     - {testimonialsData[selected].status}
                </span>
            </div>
            <div className="right-t">
                <motion.div
                initial={{ opacity:0 , x: -100 }}
                transition={{...transition,duration:2}}
                whileInView={{opacity:1, x:0}} //chế độ xem khi khong xem thi hien ve vi tri initial

                ></motion.div>
                <motion.div
                initial={{opacity:0, x:100}}
                transition={{...transition,duration:2}}
                whileInView={{opacity:1, x:0}}
                ></motion.div>
                <motion.img
                key={selected}
                initial={{opacity:0, x: 100}}
                animate={{opacity:1, x:0}}
                exit={{opacity:0, x:-100}}
                transition={transition}
                 src={testimonialsData[selected].image} alt="" />
                <div className="arrows">
                    <img onClick={()=>{
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1); //prev được coi như là giá trị hiện tại
                    }}
                    src={LeftArrows} alt="" />
                    <img onClick={() =>{
                        selected === tLength - 1
                          ? setSelected(0)
                          :setSelected((prev) => prev + 1);
                    }}
                    src={RightArrows} alt="" />
                </div>
            </div>
        </div>
     )
}
export default Testimonials