import React, { useState } from "react";
import './Header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from "react-scroll";

const Header = () =>{

   const mobile = window.innerWidth<=768 ? true : false
   const [menuOpened, setMenuOpened] = useState(false)

    return(
        <div className="header">
            <img src={Logo} alt="" className="logo"/>
            {menuOpened === false && mobile === true ?(
                <div style={{
                    backgroundColor:'var(--appColor)', 
                    padding:'0.5rem',
                    borderRadius:'5px'

                }} onClick={() => setMenuOpened(true)}>
                    <img src={Bars} alt="" style={{width: '1.5rem', height: '1.5rem'}} />
                </div>
            ) : (

            <ul className="header-menu">
                <li onClick={() => setMenuOpened(false)}>
                <Link 
                    onClick={()=>setMenuOpened(false)}
                    activeClass="active"
                    to="header" // home là id được đặt ở phẩn đầu Hero.jsx
                    spy={true} //Khi phần tử này vào hoặc ra khỏi khung nhìn, liên kết sẽ được cập nhật tương ứng,
                    // ví dụ: thêm lớp "active" khi phần tử đang trong khung nhìn, và loại bỏ nó khi phần tử không còn trong khung nhìn nữa.
                    smooth={true}
                    >
                    Trang chủ
                    </Link></li>
                <li onClick={() => setMenuOpened(false)}>
                <Link 
                    onClick={()=>setMenuOpened(false)}
                    to="Programs"
                    spy={true}
                    smooth={true}
                    >
                    Chương trình
                    </Link>
                </li>
                <li onClick={() => setMenuOpened(false)}>
                <Link 
                    onClick={()=>setMenuOpened(false)}
                    to="Reasons"
                    spy={true}
                    smooth={true}
                    >
                    Tại sao nên chọn chúng tôi?
                    </Link>
                </li>
                <li onClick={() => setMenuOpened(false)}>
                    <Link 
                    onClick={()=>setMenuOpened(false)}
                    to="plans-container" // lấy được tên class
                    spy={true}
                    smooth={true}
                    >
                    Kế hoạch
                    </Link></li>
                <li onClick={() => setMenuOpened(false)}>
                    <Link 
                    onClick={()=>setMenuOpened(false)}
                    to="Testimonials"
                    spy={true}
                    smooth={true}
                    >
                    Cảm nhận khách hàng
                    </Link>
                </li>
            </ul>
            )}
            
        </div>
    )
}
export default Header