import React from "react";
import './Reasons.css'

import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";


const Reasons = () =>{
    return(
        <div className="Reasons" id="reasons">
            <div className="left-r">
                <img src={image1} alt="" />
                <img src={image2} alt="" />
                <img src={image3} alt="" />
                <img src={image4} alt="" />
              
            </div>
            <div className="right-r">
              <span>một số lý do</span>
              <div>
                <span className="stroke-text">Tại sao</span>
                <span> chọn chúng tôi?</span>
              </div>
              <div className="details-r">
              <div>
                <img src={tick} alt=""></img>
                <span>HƠN 140+ HLV CHUYÊN NGHIỆP</span>
                </div>
              <div>
                <img src={tick} alt="" />
                <span>TẬP LUYỆN THÔNG MINH VÀ NHANH HƠN TRƯỚC</span>
                </div>
              <div>
                <img src={tick} alt="" />
                <span>MIỄN PHÍ 1 CHƯƠNG TRÌNH CHO NGƯỜI MỚI</span>
                </div>
              <div>
                <img src={tick} alt="" />
                <span>ĐỐI TÁC ĐÁNG TIN CẬY</span>
                </div>
            </div>
                <span style={{
                    color:"var(--gray)",
                    fontWeight:"normal",
                }}>
                   ĐỐI TÁC CỦA CHÚNG TÔI
                </span>
                <div className="partners">
                    <img src={nb} alt="" />
                    <img src={adidas} alt="" />
                    <img src={nike} alt="" />
                </div>
          </div>
        </div>
    )
}

export default Reasons