import React from 'react'
import './Plans.css'
import { plansData } from '../../data/plansData'
import whiteTick from "../../assets/whiteTick.png"
const Plans = () =>{
    return(
        <div className="plans-container">
            <div className="blur plans-blur-1"></div>
            <div className="blur plans-blur-2"></div>
            <div className="programs-header" style={{gap:'2rem'}}>
                <span className='stroke-text'>SẴN SÀNG BẮT ĐẦU</span>
                <span>HÀNH TRÌNH CỦA BẠN</span>
                <span className='stroke-text'>VỚI CHÚNG TÔI NGAY BÂY GIỜ</span>
            </div>

            {/* plans cart */}
            <div className="plans">
                {plansData.map((plan,i) => (
                    <div className="plan" key={i}>
                        {plan.icon}
                        <span>{plan.name}</span>
                        <span>{plan.price}</span>

                        <div className="features">
                            {plan.features.map((features,i)=>(
                                <div className="feature">
                                    <img src={whiteTick} alt="" />
                                    <span key={i}>{features}</span>
                                </div>
                            ))}
                        </div>
                        <div>
                            <span>Xem thêm lợi ích -&gt;</span>
                        </div>
                        <button className='btn'>Tham gia ngay</button>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Plans